import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SidebarPosts from "./partials/sidebar-posts"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Post = ({ data }) => {
  const post = data.wpPost
  const lang = data.acfSeo?.lang
  const seo = post.seo

  return (
    <Layout
      className="post-template container__tablet"
      breadcrumbs={seo.breadcrumbs}
    >
      <div className="post-main">
        <div className="content">
          <h1 className="red no-margin">{post?.title}</h1>
          <div className="post-meta red small-margin-top">
            <span className="date">{post?.date}</span>
            <span className="categories">
              {post?.categories?.nodes?.map((node, index) => {
                return (
                  <Link to={node.uri} title={node.name} key={index}>
                    {node.name}
                  </Link>
                )
              })}
            </span>
          </div>
          <div className="tags">
            {post?.tags?.nodes?.map((node, index) => {
              return (
                <a href={node.uri} title={node.name} key={index}>
                  {node.name}
                </a>
              )
            })}
          </div>
          <div dangerouslySetInnerHTML={{ __html: post?.content }}></div>
        </div>
      </div>
      <SidebarPosts />
      <Seo
        pageUri={post.uri}
        title={seo.title}
        description={seo.metaDesc}
        language={lang}
        canonical={seo.canonical}
        ogTitle={seo.opengraphTitle}
        ogDescription={seo.opengraphDescription}
        ogImage={seo.opengraphImage?.localFile?.childImageSharp?.fixed?.src}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage?.localFile?.childImageSharp?.fixed?.src}
      />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      uri
      title
      content
      date(formatString: "MMMM DD, Y")
      tags {
        nodes {
          name
          uri
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      acfSeo {
        language
      }
      seo {
        title
        canonical
        metaDesc
        breadcrumbs {
          url
          text
        }
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            childImageSharp {
              fixed(width: 800, height: 418) {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default Post
